<template>
  <div>
    <section class="news-and-notice-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>다비치 소식 &#40;공지&#47;뉴스&#41;</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>WHAT&#39;S NEW</span>&nbsp;&gt;&nbsp;
            <span>다비치 소식 &#40;공지&#47;뉴스&#41;</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="category">구분</label>
              </th>
              <td>

                  <select
                    name="category"
                    id="category"
                    v-model="selectedContentType"
                  >
                    <option
                      v-for="cate in contentTypeList"
                      v-bind:key="cate.type"
                      :value="cate"
                    >
                      {{ cate.name }}
                    </option>
                  </select>

              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>

              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]"></file-select>
                  </div>
                </div>
                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>

            </tr>
          </table>
          <!-- /.table -->
          <router-link
            :to="{ name: 'NewsAndNoticeList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";

export default {
  components: { AppFooter, FileSelect },
  name: "NewsAndNoticeCreate",
  data() {
    return {
      selectedContentType: null,
      contentTypeList: [ 
        {content_type: 1, name: "공지" } , 
        {content_type: 2, name: "뉴스" } ,
      ],

      files: [null, null, null, null, null],
      title: "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  created() {
    // props are exposed on `this`
    this.selectedContentType = this.contentTypeList[0] ;

  },
  methods: {

    onClickCreate() {
      console.log('onClickCreate  ' ); 

      let formData = new FormData();
      formData.append("type", this.selectedContentType.content_type );
      formData.append("title", this.title );
      formData.append("contents", this.editorData);
      
      var count = 0 ; 
      for (let attfile of this.files) {
        if( attfile != null && attfile != undefined ) {
          var fieldname = "files[" + count +"]"; 
          formData.append(fieldname, attfile );
        }
        count++;
      }

      this.$store.dispatch("whatsnews/req_create_newsandnotice", formData ).then( result => {
        console.log( 'req_create_adcontents result => ', result );
        this.$alert("등록하였습니다.").then(() => {
          this.$router.push({ name: "NewsAndNoticeList" });
        });
      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },


  },
};
</script>
