<template>
  <div>
    <section class="ad-content-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>광고&#47;홍보영상 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>WHAT &#39;S NEW</span>&nbsp;&gt;&nbsp;
            <span>광고&#47;홍보영상 관리</span>
          </p>
          <!-- /.site-path -->
          <ul class="tab-menu store-tab-menu">
            <li><button v-bind:class="{ checked: content_type == 0 }" @click="onButtonContentType(0)">전체</button></li>
            <li><button v-bind:class="{ checked: content_type == 1 }" @click="onButtonContentType(1)">TV CF</button></li>
            <li><button v-bind:class="{ checked: content_type == 2 }" @click="onButtonContentType(2)">DIGITAL CF</button></li>
          </ul>
          <!-- /.tab menu-->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" class="search-top-175" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">구분</th>
                <th scope="col">게시물 제목</th>
                <th scope="col">부제</th>
                <th scope="col">등록일</th>
              </tr>
            </thead>
            <tbody>


              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">광고 홍보영상 체크</label>
                  <input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link :to="{ name: 'AdContentEdit' , params: { id: item.id, item: item } }"> {{item.id}} </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdContentEdit' , params: { id: item.id, item: item } }">
                    {{getCategoryName(item.type)}}
                  </router-link>
                </td>
                <td class="left">
                  <router-link
                    :to="{ name: 'AdContentEdit' , params: { id: item.id, item: item }}"
                    class="of-hidden"
                  >
                    {{item.title}}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'AdContentEdit', params: { id: item.id, item: item } }"
                    class="of-hidden"
                  >
                    {{item.subtitle}}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'AdContentEdit' , params: { id: item.id, item: item }  }">
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD") }}
                  </router-link>
                </td>
              </tr>



            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="@/assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link
              :to="{ name: 'AdContentCreate' }"
              tag="a"
              class="btn-blue"
              >등록
            </router-link>
          </div>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>   

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "AdContentList",
  data() {
    return {
      content_type: 0 ,
      data_list: [ ] ,
      data_total_count: 0 ,
    };
  },
  computed: {
    getData: function() {
      return this.data_list ; 
    },
    getDataTotalCount: function() {
      return this.data_total_count ; 
    },
  },
  created() {
    this.loadData({
      page: 1,
      size: this.PAGE_SIZE,
    }) ;
  },
  methods: {
    getCategoryName(type) {
      if( type == 1 ) return "TV CF" ;
      else if( type == 2 ) return "DIGITAL CF" ;
      else return "-";
    },
    loadData( payload ) {
      this.$store.dispatch("whatsnews/req_adcontents_list", payload ).then( result => {
        console.log( 'req_adcontents_list result => ', result );
        this.data_list = result.data ; 
        this.data_total_count = result.total_count  ; 
      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },
    onSearch(text) {
      this.searchtext = text ;
      this.loadData( {
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext ,
      }); 
    },
    onButtonContentType( type ) { 
      this.content_type = type ; 
      this.loadData( {
        type: this.content_type,
        page: 1,
        size: this.PAGE_SIZE
      }); 
 
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData( {
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext ,
      }); 
    },
    onSubmitDelete()  {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {        
        this.$store.dispatch("whatsnews/req_remove_adcontents_list", {
          idarr: this.checkedValues,
        }).then( (result) => {
          this.checkedValues = [];
          this.data_list = result.data ; 
          this.data_total_count = result.total_count  ; 
        });
      }
    },
  },
};
</script>


<style scoped>
.col1 {
  width: 64px;
}
.col2 {
  width: 90px;
}
.col3 {
  width: 190px;
}
.col6 {
  width: 190px;
}
</style>
