<template>
  <div>
    <section class="news-and-notice-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont"> 
          <h2>다비치 소식 &#40;공지&#47;뉴스&#41;</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>WHAT&#39;S NEW</span>&nbsp;&gt;&nbsp;
            <span>다비치 소식 &#40;공지&#47;뉴스&#41;</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" /> 
            </colgroup>
            <tr>
              <th scope="row">
                <label for="category">구분</label>
              </th>
              <td>

                  <select
                    name="category"
                    id="category"
                    v-model="selectedContentType"
                  >
                    <option
                      v-for="cate in contentTypeList"
                      v-bind:key="cate.type"
                      :value="cate"
                    >
                      {{ cate.name }}
                    </option>
                  </select>

              </td>
            </tr>
            <tr>  
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>

              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]" v-bind:show-del=" file != null && file.name != null "></file-select>
                  </div>
                </div>
                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>

            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'NewsAndNoticeList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
import attachments_update_mixin from "../../../mixins/attachments_update_mixin";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect },
  name: "NewsAndNoticeEdit",
  mixins: [attachments_update_mixin],
  data() {
    return {
      selectedContentType: null,
      contentTypeList: [ 
        {content_type: 1, name: "공지" } , 
        {content_type: 2, name: "뉴스" } ,
      ],

      title: "",

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },

    };
  },
  created() {
    // props are exposed on `this`
    console.log("props id => ", this.id);
    console.log("props item => ", this.item);

    this.title =  this.item.title ;
    this.editorData = this.item.contents ; 
    this.selectedContentType = this.contentTypeList.find( (el) => el.content_type == this.item.type ) ;

    this.attachments = this.item.attachments ; 
    console.log('attachment => ', this.attachments) ; 

    // 첨부 파일은 
    this.init_attachments_set(); 


  },
  methods: {

    onSubmitModify() {

      console.log("onSubmitModify. -----");

      const payload = { 
        id: this.item.id ,
        type: this.selectedContentType.content_type ,
        title: this.title,
        contents: this.editorData,

      };
      console.log("onSubmitModify. payload ==> ", payload );

      this.$store.dispatch("whatsnews/req_update_newsandnotice",  payload  )
      .then(( ) => {

        this.onSubmitModifyFiles( "news_and_notice" , "NewsAndNoticeList");

        // this.$alert("수정하였습니다.").then( () => {
        //     this.$router.push({ name: "NewsAndNoticeList" });
        // });

      })
      .catch((error) => {
        console.log("error => ", error);
      });

    },
  },
};
</script>
