<template>
  <div>
    <section class="ad-content-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>광고&#47;홍보영상 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>WHAT &#39;S NEW</span>&nbsp;&gt;&nbsp;
            <span>광고&#47;홍보영상 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">공지 노출</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="store-use"
                    name="store"
                    value=1
                    checked
                    v-model="notice_open"
                  />
                  <label for="store-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="store-unuse"
                    name="store"
                    value=0
                    v-model="notice_open"
                  />
                  <label for="store-unuse">미사용</label>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="scategory">구분</label>
              </th>
              <td>

                  <select
                    name="category"
                    id="category"
                    v-model="selectedContentType"
                  >
                    <option
                      v-for="cate in contentTypeList"
                      v-bind:key="cate.type"
                      :value="cate"
                    >
                      {{ cate.name }}
                    </option>
                  </select>


              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">부제</label>
              </th>
              <td>
                <input
                  type="text"
                  id="sub-title"
                  name="sub-title"
                  v-model="subtitle"
                />
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="admin-id">유튜브 영상</label></th>
              <td class="youtube">
                <input
                  type="text"
                  name="youtube"
                  id="youtube"
                  v-model="youtube_link"
                />
                <span
                  >예)youtube.com/watch?<em>v=MDHjXD5RKH</em>을 입력
                  바랍니다.</span
                >
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'AdContentList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "AdContentEdit",
  data() { 
    return {
      selectedContentType: null,
      contentTypeList: [ 
        {content_type: 1, name: "TV CF" } , 
        {content_type: 2, name: "DIGITAL CF" } ,
      ], 

      title: "" ,
      subtitle: "" ,
      youtube_link: "" ,
      notice_open: 0, 

    };
  }, 
  created() {
    // props are exposed on `this`
    console.log("props id => ", this.id);
    console.log("props adcontent => ", this.item);
    this.title =  this.item.title ;
    this.subtitle = this.item.subtitle;
    this.youtube_link  = this.item.youtube_link ; 
    this.notice_open = this.item.notice_open ; 
    this.selectedContentType = this.contentTypeList.find( (el) => el.content_type == this.item.type ) ;

    
  },
  methods: {

    onSubmitModify() {

      console.log("onSubmitModify. -----");

      const payload = {
        id: this.item.id ,
        type: this.selectedContentType.content_type ,
        title: this.title,
        subtitle: this.subtitle,
        notice_open : this.notice_open,
        youtube_link: this.youtube_link
      };
      console.log("onSubmitModify. payload ==> ", payload );

      this.$store.dispatch("whatsnews/req_update_adcontents",  payload  )
      .then(( ) => {
        this.$alert("수정하였습니다.").then( () => {
            this.$router.push({ name: "AdContentList" });
        });
      })
      .catch((error) => {
        console.log("error => ", error);
      });

    },
 


  },
};
</script>
<style lang="scss" scoped>
select {
  width: 150px !important;
}
</style>
