<template>
  <div>
    <section class="event-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>이벤트 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>What's New</span>&nbsp;&gt;&nbsp;
            <span>이벤트 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="category">구분</label>
              </th>
              <td>
                <select
                  name="category"
                  id="category"
                  v-model="selectedCategory"
                >
                  <option
                    v-for="cate in categoryList"
                    v-bind:key="cate.category_id"
                    :value="cate"
                  >
                    {{ cate.name }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">이벤트 기간</label>
              </th>
              <td class="event-date">
                <div class="first-date">
                  <date-picker
                    v-model="event_periods"
                    valueType="format"
                    format="YYYY.MM.DD"
                    range
                    placeholder="시작일 ~ 종료일"
                    :lang="lang"
                  ></date-picker>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">대표 이미지</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMain"></file-select>
                <span
                  >사이즈 : 가로 680px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="url">URL</label>
              </th>
              <td>
                <input type="text" id="url" name="url" v-model="url" />
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'EventList' }" class="btn-white" tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            등록
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../..//FileSelect.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { AppFooter, FileSelect, DatePicker },
  name: "EventCreate",
  data() {
    return {
      selectedCategory: null,
      categoryList: [
        { category_id: 1, name: "혜택" },
        { category_id: 2, name: "쿠폰" },
        { category_id: 3, name: "세일" },
        { category_id: 4, name: "사전예약" },
        { category_id: 5, name: "얼리버드" },
      ],

      title: "",
      url: "",

      fileMain: null,

      event_periods: null,
      lang: null,
    };
  },
  created() {
    this.lang = this.datepicker_lang;
    this.selectedCategory = this.categoryList[0];
  },
  methods: {
    onClickCreate() {
      console.log("in onClickCreate ==>  title ", this.title);

      if (
        this.event_periods == null ||
        this.event_periods.length != 2 ||
        this.event_periods[0] == null ||
        this.event_periods[1] == null
      ) {
        return this.$fire({
          title: "이벤트 기간을 선택해주세요",
          text: "",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      var start_day = this.event_periods[0];
      var end_day = this.event_periods[1];

      console.log("start_day =>", start_day);
      console.log("end_day =>", end_day);

      let formData = new FormData();

      formData.append("type", this.selectedCategory.category_id);
      formData.append("title", this.title);
      formData.append("url", this.url);

      formData.append("start_day", start_day);
      formData.append("end_day", end_day);

      if (this.fileMain != null) formData.append("file", this.fileMain);

      this.$store
        .dispatch("whatsnews/req_create_event", formData)
        .then((result) => {
          console.log("req_create_event result => ", result);
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "EventList" });
          });
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
  },
};
</script>
<style lang="scss">
.event-create,
.event-edit {
  table {
    tr {
      td {
        &.event-date {
          display: flex;
        }
        select {
          width: 5.8vw !important;
          padding-left: 7px !important;
          min-width: 73px;
          & + label {
            display: inline-block;
            margin-left: 5px;
            margin-right: 13px;
            flex-shrink: 0;
          }
          &#event-date,
          &#event-month {
            width: 4.2vw !important;
            min-width: 60px;
          }
        }

        em {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
