<template>
  <div>
    <section class="event-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>이벤트 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>What's New</span>&nbsp;&gt;&nbsp;
            <span>이벤트 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="category">구분</label>
              </th>

              <td>
                <select
                  name="category"
                  id="category"
                  v-model="selectedCategory"
                >
                  <option
                    v-for="cate in categoryList"
                    v-bind:key="cate.category_id"
                    :value="cate"
                  >
                    {{ cate.name }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">이벤트 기간</label>
              </th>
              <td class="event-date">
                <div class="first-date">
                  <date-picker
                    v-model="event_periods"
                    valueType="format"
                    format="YYYY.MM.DD"
                    range
                    placeholder="시작일 ~ 종료일"
                    :lang="lang"
                  ></date-picker>
                </div>

                <div class="last-date"></div>
              </td>
            </tr>
            <tr>
              <th scope="row">대표 이미지</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select
                  v-model="fileMain"
                  v-bind:show-del="
                    this.fileMain != null && this.fileMain.name != null
                  "
                ></file-select>
                <span
                  >사이즈 : 가로 680px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="url">URL</label>
              </th>
              <td>
                <input type="text" id="url" name="url" v-model="url" />
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'EventList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../../FileSelect.vue";
import attachments_update_mixin from "../../../mixins/attachments_update_mixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect, DatePicker },
  name: "EventEdit",
  mixins: [attachments_update_mixin],
  data() {
    return {
      selectedCategory: null,
      categoryList: [
        { category_id: 1, name: "혜택" },
        { category_id: 2, name: "쿠폰" },
        { category_id: 3, name: "세일" },
        { category_id: 4, name: "사전예약" },
        { category_id: 5, name: "얼리버드" },
      ],

      // startDay: {
      //   yyyy: '',
      //   mm: '',
      //   dd: '',
      // },
      // endDay: {
      //   yyyy: '',
      //   mm: '',
      //   dd: '',
      // },

      title: "",
      url: "",

      fileMain: null,

      event_periods: null,
      lang: null,
    };
  },
  created() {
    console.log("props id => ", this.id);
    console.log("props event => ", this.item);
    this.lang = this.datepicker_lang;

    this.selectedCategory = this.categoryList.find(
      (el) => el.category_id == this.item.type
    );
    this.title = this.item.title;
    this.url = this.item.url;

    // this.startDay = this.getYearMonthDayObjectFromString( this.item.start_day );
    // this.endDay   = this.getYearMonthDayObjectFromString( this.item.end_day );

    this.event_periods = [this.item.start_day, this.item.end_day];

    this.attachments =
      this.item.attachment != null ? [this.item.attachment] : [];
    console.log("attachments => ", this.attachments);

    this.init_attachments_set();
  },
  methods: {
    // getYearMonthDayObjectFromString( inputstr ) {
    //     const arr = inputstr.split(".");
    //     console.log('arr => ', arr);
    //     return {
    //       yyyy: arr[0].toString(),
    //       mm: parseInt(arr[1]).toString(),
    //       dd: parseInt(arr[2]).toString()
    //     } ;
    // },

    // getYearMonthDayStringFromObject( inputObj ) {
    //   // make yyyy.mm.dd string
    //   const strYear = inputObj.yyyy ;
    //   const strMonth = parseInt(inputObj.mm) >= 10 ? inputObj.mm.toString() : '0' + inputObj.mm ;
    //   const strDay = parseInt(inputObj.dd) >= 10 ? inputObj.dd.toString() : '0' + inputObj.dd ;

    //   var fullyear =  strYear + '.' + strMonth +'.' + strDay ;
    //   return fullyear ;
    // },
    onSubmitModify() {
      console.log("onSubmitModify. -----");

      if (
        this.event_periods == null ||
        this.event_periods.length != 2 ||
        this.event_periods[0] == null ||
        this.event_periods[1] == null
      ) {
        return this.$fire({
          title: "이벤트 기간을 선택해주세요",
          text: "",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      // var start_day = this.getYearMonthDayStringFromObject( this.startDay) ;
      // var end_day   = this.getYearMonthDayStringFromObject( this.endDay ) ;

      var start_day = this.event_periods[0];
      var end_day = this.event_periods[1];

      console.log("start_day =>  ", start_day);
      console.log("end_day =>  ", end_day);

      const payload = {
        id: this.item.id,
        type: this.selectedCategory.category_id,
        title: this.title,
        start_day: start_day,
        end_day: end_day,
        url: this.url,
      };

      this.$store
        .dispatch("whatsnews/req_update_event", payload)
        .then(() => {
          this.onSubmitModifyFiles("event", "EventList");

          // this.$alert("수정하였습니다.").then( () => {
          //   this.$router.push({ name: "EventList" });
          // });
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
  },
};
</script>
