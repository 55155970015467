<template>
  <div>
    <section class="ad-content-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>광고&#47;홍보영상 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>WHAT &#39;S NEW</span>&nbsp;&gt;&nbsp;
            <span>광고&#47;홍보영상 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">공지 노출</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="store-use"
                    name="store"
                    value=1
                    v-model="notice_open"
                  />
                  <label for="store-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="store-unuse"
                    name="store"
                    value=0
                    v-model="notice_open"
                  />
                  <label for="store-unuse">미사용</label>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="scategory">구분</label>
              </th>
              <td>
                
                  <select
                    name="category"
                    id="category"
                    v-model="selectedContentType"
                  >
                    <option
                      v-for="cate in contentTypeList"
                      v-bind:key="cate.type"
                      :value="cate"
                    >
                      {{ cate.name }}
                    </option>
                  </select>


              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">부제</label>
              </th>
              <td>
                <input
                  type="text"
                  id="sub-title"
                  name="sub-title"
                  v-model="subtitle"
                />
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="admin-id">유튜브 영상</label></th>
              <td class="youtube">
                <input
                  type="text"
                  name="youtube"
                  id="youtube"
                  v-model="youtube_link"
                />
                <span
                  >예)youtube.com/watch?<em>v=MDHjXD5RKH</em>을 입력
                  바랍니다.</span
                >
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'AdContentList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  components: { AppFooter },
  name: "AdContentCreate",
  data() {
    return {
      selectedContentType: null,
      contentTypeList: [ 
        {content_type: 1, name: "TV CF" } , 
        {content_type: 2, name: "DIGITAL CF" } ,
      ],

      title: "" ,
      subtitle:  "" ,
      youtube_link: "" ,
      notice_open: 1, 
    }
  }, 
  created() {
    this.selectedContentType = this.contentTypeList[0] ;
  },
  methods: {

    onClickCreate() {
      console.log('onClickCreate  ' ); 

      var payload = {
        type : this.selectedContentType.content_type ,
        title: this.title ,
        subtitle: this.subtitle ,
        notice_open: this.notice_open ,
        youtube_link : this.youtube_link 
      }

      this.$store.dispatch("whatsnews/req_create_adcontents", payload ).then( result => {
        console.log( 'req_create_adcontents result => ', result );
        this.$alert("등록하였습니다.").then(() => {
          this.$router.push({ name: "AdContentList" });
        });
      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },



  },
};
</script>
